import { translator } from '@aon/cfs-utils/lib/translation';
import { VueForm, Model } from '@aon/cfs-forms';
import { maxFileSize, fileType } from '@aon/cfs-forms/lib/validators';
import {
  required,
  maxValue,
  helpers,
  minValue,
  minLength,
  maxLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import moment from 'moment';
import INSURANCE_TYPES from '@aon/cfs-utils/lib/constants/insuranceTypes';
import { SUBREQUIREMENTS } from '@/lib/constants';
import store from '@/store/store';

const maxDollarAmount = 1000000000;

const model = new Model({
  id: { default: null },
  brokeredBy: {
    default: 'other',
  },
  brokerName: {
    default: null,
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  policyType: {
    default: null,
    validations: { required },
  },
  territory: {
    default: null,
    validations: { required },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  certificateOfInsurance: {
    default: null,
    validations: { required, maxFileSize: maxFileSize(), fileType: fileType() },
  },
  coverage: {
    default: null,
    validations: {
      mustHaveCoverage: (value, fields) =>
        // !!value || !!fields.perOccurrenceCoverage,
        value !== undefined ||
        value !== null ||
        fields.perOccurrenceCoverage !== undefined ||
        fields.perOccurrenceCoverage !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  perOccurrenceCoverage: {
    default: null,
    validations: {
      // mustHaveCoverage: (value, fields) => !!value || !!fields.coverage,
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.coverage !== undefined ||
        fields.coverage !== null,
      minValue: minValue(-1),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  deductible: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: (value, fields) => {
        if (fields?.insuranceType === 'PROPERTY') {
          return maxValue(maxDollarAmount)(value);
        }

        return maxValue(
          helpers.req(fields.coverage) &&
            fields.coverage < maxDollarAmount &&
            helpers.req(fields.perOccurrenceCoverage) &&
            fields.perOccurrenceCoverage < maxDollarAmount
            ? Math.max(fields.coverage, fields.perOccurrenceCoverage)
            : helpers.req(fields.coverage) && fields.coverage < maxDollarAmount
            ? fields.coverage
            : helpers.req(fields.perOccurrenceCoverage) &&
              fields.perOccurrenceCoverage < maxDollarAmount
            ? fields.perOccurrenceCoverage
            : maxDollarAmount
        )(value);
      },
    },
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  insuranceType: {
    default: null,
    validations: { required },
  },
  vendorId: {
    default: null,
    validations: { required },
  },
  namedEndorsements: {
    default: [],
  },
  selectedAdditionalCoverages: {
    default: [],
  },
  sublimits: {
    default: [],
  },
  additionalCoverages: {
    default: [],
    validations: {
      requiredIf: requiredIf(
        fields => fields.selectedAdditionalCoverages.length > 0
      ),
    },
  },
  coverWalletAccountId: {
    default: null,
    validations: {
      // cwRequired: (value, fields) =>
      //   fields.brokeredBy !== 'coverwallet' || !!value,
    },
  },
  clientName: {
    default: null,
    validations: {
      required,
    },
  },
  coiClientSubRequirements: {
    default: [],
  },
});

export const policyTypes = Object.values(
  translator.$t(`insurancePolicyModal.policyTypes`)
);
export const policyTypeKeys = Object.keys(
  translator.$t(`insurancePolicyModal.policyTypes`)
);
export const countryTypes = Object.values(translator.$t(`countries.countries`));
export const countryKeys = Object.keys(translator.$t(`countries.countries`));

export const territoryTypeOptions = new Array(countryTypes.length)
  .fill()
  .map((_, index) => ({
    value: countryKeys[index],
    text: countryTypes[index].name,
  }));

export const policyTypeOptions = new Array(policyTypes.length)
  .fill()
  .map((_, index) => ({
    value: policyTypeKeys[index],
    text: policyTypes[index].name,
  }));

export const insuranceTypeOptions = Object.values(INSURANCE_TYPES).map(
  insuranceType => {
    return {
      text: translator.$t(`insurancePolicyModal.typeSimple.${insuranceType}`),
      value: insuranceType,
    };
  }
);

export default function InsurancePolicyForm(initialValues) {
  let policyInitialValues = initialValues;
  const brokeredBy = initialValues.brokeredBy;

  if (brokeredBy !== 'aon' && brokeredBy !== 'coverwallet') {
    policyInitialValues.brokerName = brokeredBy;
    policyInitialValues.brokeredBy = 'other';
  }

  if (policyInitialValues?.subrequirements) {
    const newSubrequirements = policyInitialValues.subrequirements
      .filter(
        sr => sr.type === 'COI_CLIENT' && sr.name !== 'CLIENT_COMPANY_NAME'
      )
      .map(ccr => ccr.name);

    store.commit(
      'insurancePolicy/setPolicyCoiClientSubRequirements',
      newSubrequirements
    );

    const clientCompanyName = policyInitialValues.subrequirements
      .filter(
        sr => sr.type === 'COI_CLIENT' && sr.name === 'CLIENT_COMPANY_NAME'
      )
      .map(cc => cc.value);

    policyInitialValues.clientName =
      clientCompanyName.length > 0 ? clientCompanyName[0] : null;

    policyInitialValues.namedEndorsements = policyInitialValues.subrequirements
      .filter(sr => sr.type === 'ENDORSEMENT')
      .map(end => end.name);

    let subrequirements = [];
    let currentAdditionalCoverages = [];
    const finalAdditionalCoverages = [];
    const insuranceType = policyInitialValues.insuranceType;

    let availableSublimits = [];
    let currentSublimits = [];
    let amountSublimits = [];
    let booleanSublimits = [];

    const HAS_SUBLIMITS = [
      'GL',
      'COM_AUTO',
      'WRK_COMP',
      'PROPERTY',
      'TECH_EO',
      'EMPL_PRACT_LIAB',
      'UMB_EXCESS',
    ];

    if (policyInitialValues.vendorId && HAS_SUBLIMITS.includes(insuranceType)) {
      availableSublimits = SUBREQUIREMENTS['SUB_LIMITS'][insuranceType];

      currentSublimits = policyInitialValues.subrequirements.filter(
        sr => sr.type === 'SUB_LIMIT'
      );

      availableSublimits.forEach(sublimit => {
        const tempSub = currentSublimits.find(
          current => current.name === sublimit.name
        );

        if (tempSub) {
          if (tempSub?.meta?.typeValue === 'AMOUNT') {
            amountSublimits.push({
              ...tempSub,
            });
          } else if (
            tempSub.meta === null ||
            tempSub?.meta?.typeValue === 'BOOL'
          ) {
            booleanSublimits.push({
              ...tempSub,
              meta: {
                ...tempSub.meta,
                selectedValue: tempSub.name,
              },
            });
          }
        } else {
          if (sublimit?.meta?.typeValue === 'AMOUNT') {
            amountSublimits.push({
              ...sublimit,
            });
          } else if (
            sublimit.meta === null ||
            sublimit?.meta?.typeValue === 'BOOL'
          ) {
            booleanSublimits.push({
              ...sublimit,
              meta: {
                ...sublimit.meta,
                selectedValue: null,
              },
            });
          }
        }
      });

      currentAdditionalCoverages = policyInitialValues.subrequirements.filter(
        sr => sr.type === 'ADDITIONAL_COVERAGE'
      );

      if (insuranceType === 'GL') {
        subrequirements = SUBREQUIREMENTS['AUTO_LIAB'].filter(
          ac => ac.value == 'HIRED' || ac.value == 'NON_OWNED'
        );
      }

      if (insuranceType === 'COM_AUTO') {
        subrequirements = SUBREQUIREMENTS['AUTO_LIAB'];
      }

      subrequirements.forEach(sub => {
        const tempSubrequirement = currentAdditionalCoverages.find(
          current => current.value === sub.value
        );

        if (tempSubrequirement) {
          finalAdditionalCoverages.push({
            ...tempSubrequirement,
            meta: {
              selectedValue: tempSubrequirement.value,
            },
          });
        } else {
          finalAdditionalCoverages.push({
            ...sub,
            meta: {
              selectedValue: null,
            },
          });
        }
      });
    }

    policyInitialValues.amountSublimits = amountSublimits;
    policyInitialValues.booleanSublimits = booleanSublimits;
    policyInitialValues.additionalCoverages = finalAdditionalCoverages;

    if (policyInitialValues.additionalCoverages.length > 0) {
      policyInitialValues.selectedAdditionalCoverages = [
        policyInitialValues.insuranceType,
      ];
    }
    policyInitialValues.sublimits = [
      ...new Set(
        policyInitialValues.subrequirements
          .filter(sr => sr.type === 'SUB_LIMIT')
          .map(ac => ac.name)
      ),
    ];
  }

  return VueForm({
    model,
    initialValues: policyInitialValues,
    customErrorMessages: {
      greaterThanToday: translator.$t('insurancePolicyModal.greaterThanToday'),
      greaterThanEffectiveDate: translator.$t(
        'insurancePolicyModal.greaterThanEffectiveDate'
      ),
      mustHaveCoverage: translator.$t('insurancePolicyModal.mustHaveCoverage'),
      cwRequired: translator.$t('policies.coverWalletRequired'),
    },
    data: {
      insuranceTypeOptions: insuranceTypeOptions.filter(
        insurance => insurance.value !== 'WCOMP_LIAB'
      ),
      policyTypeOptions,
      territoryTypeOptions,
    },
    methods: {
      resetAdditionalInsured() {
        this.$v.fields.additionalInsured.$model = null;
      },
      getFormValues() {
        const values = this._getFormValues();
        delete values.insuranceTypeId;

        values.effectiveDate = moment.utc(values.effectiveDate).format();
        values.expirationDate = moment.utc(values.expirationDate).format();

        return values;
      },
    },
  });
}
