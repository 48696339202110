import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { Model, VueForm } from '@aon/cfs-forms';

const vendorModel = new Model({
  name: {
    default: null,
    validations: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100),
    },
  },
  addressType: {
    default: 'CORPORATE',
    validations: { required },
  },
  country: {
    default: 'USA',
    validations: { required, maxLength: maxLength(3) },
  },
  addressLine1: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  addressLine2: {
    default: null,
    validations: { maxLength: maxLength(100) },
  },
  addressLine3: {
    default: null,
    validations: { maxLength: maxLength(100) },
  },
  locality: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  county: {
    default: null,
    validations: { maxLength: maxLength(100) },
  },
  region: {
    default: null,
    validations: { maxLength: maxLength(100), required },
  },
  postalCode: {
    default: null,
    validations: {
      minLength: minLength(3),
      maxLength: maxLength(10),
      required,
    },
  },
  proxyCompanyId: {
    default: null,
  },
});

export function VendorForm() {
  return VueForm({
    model: vendorModel,
    fields: vendorModel.getFields(),
    methods: {
      getName() {
        return this.fields.name;
      },
      getAddress() {
        return {
          addressType: this.fields.addressType,
          country: this.fields.country,
          addressLine1: this.fields.addressLine1,
          addressLine2: this.fields.addressLine2,
          addressLine3: this.fields.addressLine3,
          locality: this.fields.locality,
          county: this.fields.county,
          region: this.fields.region,
          postalCode: this.fields.postalCode,
        };
      },
    },
  });
}
