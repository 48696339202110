import { VueForm, Model } from '@aon/cfs-forms';
import { maxFileSize, fileType } from '@aon/cfs-forms/lib/validators';
import { required } from 'vuelidate/lib/validators';

const model = new Model({
  id: { default: null },
  certificateOfInsurance: {
    default: null,
    validations: { required, maxFileSize: maxFileSize(), fileType: fileType() },
  },
});

export default function InsurancePolicyForm(initialValues) {
  return VueForm({
    model,
    initialValues,
    customErrorMessages: {},
    data: {},
    methods: {
      getFormValues() {
        const values = this._getFormValues();
        delete values.insuranceTypeId;
        return values;
      },
    },
  });
}
