<template>
  <div>
    <div
      v-for="sublimit in sublimits"
      :key="sublimit.name"
      class="input-container"
    >
      <p>
        {{ $t(`policies.SUB_LIMIT.${insuranceType}.${sublimit.name}`) }}
      </p>

      <v-text-field
        v-model="sublimit.value"
        type="number"
        class="hide-number-spinner field"
        dense
        outlined
        hide-details
        :prefix="requirementPrefix(insuranceType)"
        :rules="[rules.required, rules.counter]"
      />
    </div>
  </div>
</template>

<script>
import { requirementPrefix } from '@/lib/helpers';

export default {
  name: 'SubLimitsAmountItems',
  props: {
    insuranceType: {
      type: String,
      default: '',
    },
    sublimits: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value?.length > 0 || 'Min 1 characters',
      },
    };
  },
  methods: {
    requirementPrefix,
  },
};
</script>
<style lang="scss" scoped>
.input-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .field {
    margin-left: 10px;
    max-width: 220px;
  }
}
</style>
