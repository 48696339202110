<template>
  <div class="boolean-field">
    <v-checkbox
      v-model="checked"
      color="aon-peacock"
      :label="fieldProps.name"
    />
  </div>
</template>

<script>
export default {
  name: 'BooleanField',
  props: {
    fieldProps: {
      type: Object,
      default: () => {
        return {
          id: '',
          parent: null,
          name: '',
          fieldType: 'BOOL',
          values: null,
          defaultValue: null,
          priSection: '',
          validation: '',
          parentValue: null,
          isArray: false,
          isMultiple: false,
          extractionKey: null,
        };
      },
    },
  },
  data() {
    return {
      checked: this.fieldProps.defaultValue,
    };
  },
  watch: {
    checked() {
      this.$emit('change', {
        id: this.fieldProps.id,
        checked: this.checked,
      });
    },
  },
};
</script>
<style scoped>
.boolean-field .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
