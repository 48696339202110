import { Model, VueForm } from '@aon/cfs-forms';
import { maxFileSize, fileType } from '@aon/cfs-forms/lib/validators';

const model = new Model({
  file: {
    default: null,
    validations: { maxFileSize: maxFileSize(), fileType: fileType() },
  },
});

export default function Form() {
  return VueForm({ model });
}
