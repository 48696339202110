<template>
  <div>
    <div v-if="booleanCoverages.length">
      <BooleanField
        v-for="coverage in booleanCoverages"
        :key="coverage.id"
        :field-props="coverage"
        @change="updateCoverages"
      />
    </div>
  </div>
</template>

<script>
import BooleanField from '@/components/CoverageMatrix/BooleanField.vue';

export default {
  name: 'BooleanCoverages',
  components: {
    BooleanField,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
    policyCoverages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      booleanCoverages: [],
    };
  },
  created() {
    if (!this.isEditView) {
      this.booleanCoverages = this.items;
    } else {
      const coverageIds = this.policyCoverages.map(
        coverage => coverage.coverageId
      );

      for (let coverage of this.items) {
        if (coverageIds.includes(coverage.id)) {
          coverage.defaultValue = true;
          this.updateCoverages({
            id: coverage.id,
            checked: true,
          });
        }
        this.booleanCoverages.push(coverage);
      }
    }
  },
  methods: {
    updateCoverages(coverage) {
      this.$emit('change', coverage);
    },
  },
};
</script>
