<template>
  <v-dialog v-model="open" max-width="420" persistent>
    <div id="insurance-modal" class="insurance-policy-modal">
      <Card>
        <div class="d-flex align-center justify-space-between mb-8">
          <h4>{{ $t('insurancePolicyModal.addPolicy') }}</h4>
          <AppButton variation="icon" @click="closeDialog">
            <i class="fal fa-times" />
          </AppButton>
        </div>

        <v-form class="add-policy-inner">
          <LabeledInput
            :label="$t('insurancePolicyModal.certificateOfInsurance')"
            :wrapped="false"
            required
          >
            <FileInput
              v-model="$v.fields.certificateOfInsurance.$model"
              :error-messages="
                policyForm.errors($v.fields.certificateOfInsurance)
              "
              required
            />
          </LabeledInput>
          <Alert v-if="error" persistent full-width type="error" class="mt-2">
            {{ error }}
          </Alert>
          <div class="d-flex mt-2">
            <AppButton class="mr-2" :loading="saving" @click="savePolicy">
              {{ $t('form.saveChanges') }}
            </AppButton>
            <AppButton
              :disabled="saving"
              variation="ghost"
              @click="closeDialog"
            >
              {{ $t('form.cancel') }}
            </AppButton>
          </div>
        </v-form>
      </Card>
    </div>
    <v-dialog v-model="showUploadMessage" max-width="360">
      <Card>
        <div
          class="d-flex flex text-center mb-8 justify-center align-center align-content-center"
        >
          <h4>{{ uploadMessage }}</h4>
        </div>
        <div class="ml-16">
          <AppButton
            class="ml-4"
            :loading="saving"
            @click="closeUploadMessageModal"
            >{{ $t('policies.details.closeModal') }}</AppButton
          >
        </div>
      </Card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import FileInput from '@aon/cfs-components/src/components/Forms/FileInput';
import InsurancePolicyForm from '@/lib/forms/insurance-policy-only-pdf';
import { uploadInsuranceCertificate } from '@/api/policies';
import { uploadPDF } from '@/api/insurancePolicies';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'PolicyUploadPdfModal',
  components: {
    LabeledInput,
    FileInput,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      policyForm: InsurancePolicyForm(this.policy),
      saving: false,
      error: null,
      showUploadMessage: false,
      uploadMessage: null,
    };
  },
  computed: {
    fields() {
      return this.policyForm.fields;
    },
    $v() {
      return this.policyForm.$v;
    },
  },
  methods: {
    async uploadPDFDocument(payload) {
      try {
        await uploadInsuranceCertificate(payload);
      } catch (error) {
        this.error = this.$t('global.genericErrorMsg');
      }
    },

    async savePolicy() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const policy = this.policyForm.getFormValues();
      this.saving = true;
      const newCorrelationId = uuidv4();
      const emptyGUID = '00000000-0000-0000-0000-000000000000';

      const uploadedFile = policy.certificateOfInsurance;

      if (!uploadedFile) {
        this.saving = false;
        return;
      }

      const fileTypeAllowed = 'application/pdf';
      const fileExtensionAllowed = 'pdf';
      const fileType = uploadedFile.type;
      const possibleExtensions = uploadedFile.name.split('.');
      const fileExtension = possibleExtensions[possibleExtensions.length - 1];

      if (
        fileType !== fileTypeAllowed ||
        fileExtension !== fileExtensionAllowed
      ) {
        this.error = this.$t('policies.fileTypeErrorMsg');
        this.saving = false;
        return;
      }

      try {
        this.error = null;
        await this.uploadDocument({
          file: uploadedFile,
          correlationId: newCorrelationId,
        });
        this.showUploadMessageModal(this.$t('policies.details.fileUploaded'));

        await this.uploadPDFDocument({
          file: uploadedFile,
          policyId: newCorrelationId,
          tenantId: emptyGUID, // GUID empty
        });

        this.$emit('updated');
        this.closeDialog();
      } catch (error) {
        this.error = this.$t('global.genericErrorMsg');
        this.showUploadMessageModal = this.$t(
          'policies.details.fileUploadedError'
        );
      } finally {
        this.saving = false;
      }
    },
    async uploadDocument(payload) {
      try {
        if (payload.correlationId) {
          const payloadWithGuid = {
            file: payload.file,
            correlationId: payload.correlationId,
          };
          await uploadPDF(payloadWithGuid);
        } else {
          await uploadPDF(payload);
        }
      } catch (error) {
        this.error = this.$t('global.genericErrorMsg');
      }
    },
    showUploadMessageModal(message) {
      this.uploadMessage = message;
      this.showUploadMessage = true;
    },
    closeUploadMessageModal() {
      this.uploadMessage = '';
      this.showUploadMessage = false;
    },
    closeDialog() {
      this.policyForm.reset().$v.$reset();
      this.$emit('update:open', false);
      this.form = InsurancePolicyForm(this.policy);
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-policy-modal {
  .add-policy-inner {
    max-width: 420px;
  }

  .col {
    max-height: 5rem !important;
  }
}
</style>
