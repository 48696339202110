export const COVERAGE = [
  'GL',
  'WRK_COMP',
  'UMB_EXCESS',
  'CYBER',
  'INLAND_MARINE',
  'ERRS_OMISNS',
  'PROF_LIAB',
  'PROD_LIAB',
  'TECH_EO',
  'EMPL_PRACT_LIAB',
  'DIRECT_OFFICE',
  'IP',
  'PROPERTY',
  'EO_PROF_LIAB',
  'LIQUOR_LIAB',
];

export const PER_OCCURRENCE = [
  'GL',
  'COM_AUTO',
  'WRK_COMP',
  'BUSINESS',
  'UMB_EXCESS',
  'CYBER',
  'ERRS_OMISNS',
  'PROF_LIAB',
  'PROD_LIAB',
  'TECH_EO',
  'MISC_RENT_EQUIP',
  'MISC_EQUIP',
  'EMPL_PRACT_LIAB',
  'DIRECT_OFFICE',
  'FID_LIAB',
  'EO_PROF_LIAB',
  'LIQUOR_LIAB',
];

export const DEDUCTIBLE = [
  'GL',
  'COM_AUTO',
  'WRK_COMP',
  'BUSINESS',
  'UMB_EXCESS',
  'CYBER',
  'INLAND_MARINE',
  'ERRS_OMISNS',
  'PROF_LIAB',
  'PROD_LIAB',
  'TECH_EO',
  'MISC_RENT_EQUIP',
  'MISC_EQUIP',
  'EMPL_PRACT_LIAB',
  'DIRECT_OFFICE',
  'FID_LIAB',
  'IP',
  'PROPERTY',
  'EO_PROF_LIAB',
  'LIQUOR_LIAB',
];

const LIMITS_MATRIX = {
  COVERAGE,
  PER_OCCURRENCE,
  DEDUCTIBLE,
};

Object.freeze(LIMITS_MATRIX);

export default LIMITS_MATRIX;
