<template>
  <div class="vendor-fields mb-4">
    <v-row>
      <v-col class="pb-0">
        <h5 class="aon-peacock--text mb-4 mt-4">
          {{ $t('policies.addVendor') }}
        </h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('policies.name')" :required="null">
          <v-text-field
            v-model="$v.fields.name.$model"
            :error-messages="vendorForm.errors($v.fields.name)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>

    <LabeledInput
      :label="$t('policies.managedBy')"
      :required="false"
      class="py-2"
    >
      <v-autocomplete
        v-model="$v.fields.proxyCompanyId.$model"
        :items="companies"
        :loading="companiesSearching"
        :menu-props="autocompleteMenuProps"
        :search-input.sync="companiesSearch"
        dense
        item-value="id"
        item-text="name"
        outlined
        clearable
        :placeholder="$t('policies.companySearch')"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              <Spinner
                v-if="companiesSearching"
                class="absolute-center"
                :width="25"
              ></Spinner>
              <template v-else>
                {{
                  $t(
                    `policies.${
                      companiesHasSearched ? 'noCompanies' : 'companySearch'
                    }`
                  )
                }}
              </template>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </LabeledInput>

    <v-row>
      <v-col class="py-0">
        <CountrySelect
          v-model="$v.fields.country.$model"
          :error-messages="vendorForm.errors($v.fields.country)"
          :required="null"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.address', [1])" :required="null">
          <v-text-field
            v-model="$v.fields.addressLine1.$model"
            :error-messages="vendorForm.errors($v.fields.addressLine1)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.address', [2])" :required="false">
          <v-text-field
            v-model="$v.fields.addressLine2.$model"
            :error-messages="vendorForm.errors($v.fields.addressLine2)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.address', [3])" :required="false">
          <v-text-field
            v-model="$v.fields.addressLine3.$model"
            :error-messages="vendorForm.errors($v.fields.addressLine3)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.city')" :required="null">
          <v-text-field
            v-model="$v.fields.locality.$model"
            :error-messages="vendorForm.errors($v.fields.locality)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.county')" :required="false">
          <v-text-field
            v-model="$v.fields.county.$model"
            :error-messages="vendorForm.errors($v.fields.county)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <LabeledInput :label="$t('address.state')" :required="null">
          <v-text-field
            v-model="$v.fields.region.$model"
            :error-messages="vendorForm.errors($v.fields.region)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12" md="5">
        <LabeledInput :label="$t('address.postalCode')" :required="null">
          <v-text-field
            v-model="$v.fields.postalCode.$model"
            :error-messages="vendorForm.errors($v.fields.postalCode)"
            dense
            outlined
          />
        </LabeledInput>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VendorForm } from '@/lib/forms/vendor';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import CountrySelect from '@aon/cfs-components/src/components/Forms/CountrySelect';
import { debounce } from 'lodash-es';
import { searchCompanies } from '@/api/vendors';

export default {
  name: 'VendorFields',
  components: {
    LabeledInput,
    CountrySelect,
  },
  data() {
    return {
      vendorForm: VendorForm(),
      companies: [],
      companiesSearch: null,
      companiesSearching: false,
      companiesHasSearched: false,
      autocompleteMenuProps: {
        closeOnContentClick: true,
        maxHeight: 140,
      },
    };
  },
  computed: {
    $v() {
      return this.vendorForm.$v;
    },
  },
  watch: {
    companiesSearch: debounce(async function(query) {
      if (this.companiesSearching || query === null || query.length < 3) {
        return;
      }
      this.companiesSearching = true;
      await searchCompanies(query)
        .then(response => (this.companies = response.data))
        .catch(() =>
          this.$AppEventBus.$emit('snacktime', {
            message: this.$t('policies.searchError'),
            type: 'error',
          })
        )
        .finally(() => (this.companiesSearching = false));
    }, 200),
  },
};
</script>

<style scoped></style>
