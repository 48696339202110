<template>
  <div>
    <v-checkbox
      v-for="sublimit in sublimits"
      :key="sublimit.value"
      v-model="sublimit.meta.selectedValue"
      color="aon-peacock"
      class="mt-0"
      :value="sublimit.name"
      :label="$t(`policies.SUB_LIMIT.${insuranceType}.${sublimit.name}`)"
      @change="!sublimit.meta.selectedValue"
    />
  </div>
</template>

<script>
export default {
  name: 'SublimitsBooleanItems',
  props: {
    insuranceType: {
      type: String,
      default: '',
    },
    sublimits: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>
