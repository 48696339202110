<template>
  <div class="policies">
    <h2 class="aon-peacock--text pt-7 pb-6">{{ $t('policies.pageTitle') }}</h2>
    <v-row>
      <v-col cols="5">
        <div class="policy-filters">
          <TableFiltersSelect
            :filter-types="filterTypes"
            bottom-chips
            @change="getPolicies"
          />
          <v-text-field
            v-model="searchTerm"
            class="py-2"
            :placeholder="$t('policies.search')"
            outlined
            dense
            hide-details
            @input="filterPolicies"
          >
            <v-icon slot="append" small>fal fa-search</v-icon>
          </v-text-field>
        </div>
      </v-col>
      <v-col class="text-right">
        <AppButton
          class="mr-2"
          variation="subtle"
          @click="showUploadPolicyPdfDialog = true"
        >
          {{ $t('policies.addPdf') }}
        </AppButton>
        <AppButton @click="showCreatePolicyDialog = true">
          {{ $t('policies.addPolicy') }}
        </AppButton>
      </v-col>
    </v-row>
    <v-card class="mt-2">
      <v-data-table
        hide-default-header
        hide-default-footer
        :expanded="expanded"
        :headers="policiesHeaders"
        :items="policies"
        :loading="loading"
        :options.sync="pagingOptions"
        :server-items-length="totalPolicies"
      >
        <template #header="{ props: { headers, options }, on: { sort } }">
          <table-header :headers="headers" :options="options" @sort="sort" />
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <PolicyDetails
              :key="`policy_details_${item.id}`"
              :policy-id="item.id"
              @updated="policyUpdated"
            />
          </td>
        </template>

        <template #item.policyNumber="{ item }">
          <div class="policy-number">
            <AppButton variation="icon" @click="expandRow(item)">
              <i class="fal fa-angle-right" />
            </AppButton>
            {{ item.policyNumber || 'N/A' }}
          </div>
        </template>

        <template #item.policyHolder="{ item }">
          {{ item.policyHolder || 'N/A' }}
        </template>

        <template #item.insuranceType="{ item }">
          {{ $t(`mappings.insurance.${item.insuranceType}`) }}
        </template>

        <template #item.coverage="{ item }">
          {{ formatRequirement(item.insuranceType, item.coverage) }}
        </template>

        <template #item.perOccurrenceCoverage="{ item }">
          {{
            formatRequirement(item.insuranceType, item.perOccurrenceCoverage)
          }}
        </template>

        <template #item.expirationDate="{ item }">
          {{ item.expirationDate | longDate }}
        </template>

        <template #item.expand="{ item }">
          <AppButton variation="icon" @click="expandRow(item)">
            <i
              :class="expanded.includes(item) ? 'far fa-minus' : 'far fa-plus'"
            />
          </AppButton>
        </template>

        <template #footer="{ props: { pagination } }">
          <table-footer
            :pagination.sync="pagination"
            :options.sync="pagingOptions"
          />
        </template>
      </v-data-table>
    </v-card>

    <PolicyModal
      key="PolicyModal"
      :open.sync="showCreatePolicyDialog"
      :policy="newPolicy()"
      is-broker
      @updated="policyUpdated"
      @searchForDuplicate="searchForDuplicate"
    />
    <PolicyUploadPdfModal
      key="PolicyUploadPdfModal"
      :open.sync="showUploadPolicyPdfDialog"
      :policy="newPolicyPdf"
    />
  </div>
</template>

<script>
import { getAllPolicies } from '@/api/insurancePolicies';
import PolicyDetails from '@/components/Policies/PolicyDetails';
import TableFiltersSelect from '@aon/cfs-components/src/components/DataTables/TableFiltersSelect';
import TableFooter from '@aon/cfs-components/src/components/DataTables/TableFooter';
import TableHeader from '@aon/cfs-components/src/components/DataTables/TableHeader';
import PolicyModal from '@/components/Policies/PolicyModal';
import PolicyUploadPdfModal from '@/components/Policies/PolicyUploadPdfModal';
import { formatRequirement } from '@/lib/helpers';
import { debounce } from 'lodash-es';

export default {
  name: 'Policies',
  components: {
    PolicyDetails,
    TableFiltersSelect,
    TableFooter,
    TableHeader,
    PolicyModal,
    PolicyUploadPdfModal,
  },
  data() {
    return {
      expanded: [],
      filterTypes: [
        {
          typeText: this.$t('policies.headers.insuranceType'),
          typeValue: 'insuranceType',
          values: [
            {
              text: this.$t('mappings.insurance.ERRS_OMISNS'),
              value: 'ERRS_OMISNS',
            },
            {
              text: this.$t('mappings.insurance.IP'),
              value: 'IP',
            },
            {
              text: this.$t('mappings.insurance.CYBER'),
              value: 'CYBER',
            },
            {
              text: this.$t('mappings.insurance.GL'),
              value: 'GL',
            },
            {
              text: this.$t('mappings.insurance.WRK_COMP'),
              value: 'WRK_COMP',
            },
            {
              text: this.$t('mappings.insurance.COM_AUTO'),
              value: 'COM_AUTO',
            },
            {
              text: this.$t('mappings.insurance.PROD_LIAB'),
              value: 'PROD_LIAB',
            },
            {
              text: this.$t('mappings.insurance.PROPERTY'),
              value: 'PROPERTY',
            },
            {
              text: this.$t('mappings.insurance.BUSINESS'),
              value: 'BUSINESS',
            },
            {
              text: this.$t('mappings.insurance.UMB_EXCESS'),
              value: 'UMB_EXCESS',
            },
            {
              text: this.$t('mappings.insurance.INLAND_MARINE'),
              value: 'INLAND_MARINE',
            },
            {
              text: this.$t('mappings.insurance.MISC_EQUIP'),
              value: 'MISC_EQUIP',
            },
            {
              text: this.$t('mappings.insurance.AUTO_LIAB'),
              value: 'AUTO_LIAB',
            },
            {
              text: this.$t('mappings.insurance.EO_PROF_LIAB'),
              value: 'EO_PROF_LIAB',
            },
            {
              text: this.$t('mappings.insurance.TECH_EO'),
              value: 'TECH_EO',
            },
            {
              text: this.$t('mappings.insurance.EMPL_PRACT_LIAB'),
              value: 'EMPL_PRACT_LIAB',
            },
            {
              text: this.$t('mappings.insurance.DIRECT_OFFICE'),
              value: 'DIRECT_OFFICE',
            },
            {
              text: this.$t('mappings.insurance.FID_LIAB'),
              value: 'FID_LIAB',
            },
            {
              text: this.$t('mappings.insurance.LIQUOR_LIAB'),
              value: 'LIQUOR_LIAB',
            },
            {
              text: this.$t('mappings.insurance.MISC_RENT_EQUIP'),
              value: 'MISC_RENT_EQUIP',
            },
            {
              text: this.$t('mappings.insurance.LIQUOR_LIAB'),
              value: 'LIQUOR_LIAB',
            },
          ],
        },
      ],
      loading: false,
      pagingOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      totalPolicies: 0,
      policies: [],
      policiesHeaders: [
        {
          value: 'policyNumber',
          text: this.$t('policies.headers.policyNumber'),
          sortable: false,
        },
        {
          value: 'policyHolder',
          text: this.$t('policies.headers.insured'),
          sortable: false,
        },
        {
          value: 'insuranceType',
          text: this.$t('policies.headers.insuranceType'),
          sortable: false,
        },
        {
          value: 'carrier',
          text: this.$t('policies.headers.carrier'),
          sortable: false,
        },
        {
          value: 'coverage',
          text: this.$t('policies.headers.coverage'),
          sortable: false,
        },
        {
          value: 'perOccurrenceCoverage',
          text: this.$t('policies.headers.perOccurrenceCoverage'),
          sortable: false,
        },
        {
          value: 'expirationDate',
          text: this.$t('policies.headers.expirationDate'),
          sortable: false,
        },
        {
          value: 'isActive',
          text: this.$t('policies.headers.policyStatus'),
          sortable: false,
        },
        {
          value: 'expand',
          text: '',
          sortable: false,
        },
      ],
      filters: [],
      showCreatePolicyDialog: false,
      showUploadPolicyPdfDialog: false,
      searchTerm: '',
    };
  },
  computed: {
    newPolicyPdf() {
      return {};
    },
  },
  watch: {
    pagingOptions: {
      handler() {
        this.getPolicies();
      },
      deep: true,
    },
  },
  created() {
    this.getPolicies();
    this.$store.commit('insurancePolicy/setDocumentsData', []);
    this.$store.commit('insurancePolicy/setPolicies', []);
  },
  methods: {
    formatRequirement,
    newPolicy() {
      return {
        carrier: null,
        insuranceType: null,
        vendorId: null,
        coverage: null,
        deductible: 0,
        perOccurrenceCoverage: 0,
        effectiveDate: null,
        expirationDate: null,
        subrequirements: [],
      };
    },
    expandRow(policy) {
      if (!this.expanded.length) {
        this.expanded = [policy];
        return;
      }

      if (policy.id === this.expanded[0].id) {
        this.expanded = [];
        return;
      }

      this.expanded = [policy];
    },
    filterPolicies: debounce(function() {
      this.getPolicies();
    }, 500),
    async getPolicies(filters = null, searchText = null) {
      this.loading = true;

      if (filters != null) {
        this.filters = filters;
      }

      const userInput = this.searchTerm.toLowerCase().trim();

      let params = {
        page: userInput
          ? (this.pagingOptions.page = 1)
          : this.pagingOptions.page,
        pageSize: this.pagingOptions.itemsPerPage,
        searchText: userInput || searchText,
        orderBy: 'updated',
        orderDesc: true,
        anyReviewer: true,
        ...this.filters,
      };

      try {
        const { data } = await getAllPolicies(params);
        this.policies = data.results.map(policy => {
          const { effectiveDate, expirationDate } = policy;
          const currentDate = new Date().getTime();
          const startDate = new Date(effectiveDate).getTime();
          const endDate = new Date(expirationDate).getTime();
          const isActive = currentDate > startDate && currentDate < endDate;

          return {
            ...policy,
            isActive: isActive ? 'Active' : 'Inactive',
          };
        });

        this.totalPolicies = data.rowCount;
      } catch (error) {
        this.$logger.error(error);
      }

      this.loading = false;
    },
    policyUpdated() {
      this.getPolicies();
    },
    async searchForDuplicate(duplicatePolicyId) {
      this.filters = [];
      await this.getPolicies(null, duplicatePolicyId);
    },
  },
};
</script>

<style lang="scss">
.policy-filters {
  display: flex;
  align-items: center;

  .v-input__control {
    margin-left: 30px;
  }

  .v-input__slot {
    margin-bottom: 0 !important;
  }
}

.policies {
  .v-data-table {
    td {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-start {
      color: $aon-gray-01;

      .policy-number {
        .v-btn {
          cursor: pointer;

          .fa-plus {
            transition: transform 200ms;
          }
        }
      }
    }

    .v-data-table__expanded {
      &.v-data-table__expanded__row {
        background: #eee;

        .text-start {
          border-bottom-color: #eee;

          .fa-angle-right {
            transform: rotate(90deg);
          }
        }
      }

      &.v-data-table__expanded__content {
        background: #eee;
        box-shadow: none;
      }
    }
  }
}
</style>
