// Revert
export const AUTO_LIAB = [
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'AUTO_LIAB',
    value: 'ANY',
  },
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'AUTO_LIAB',
    value: 'OWNED',
  },
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'AUTO_LIAB',
    value: 'NON_OWNED',
  },
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'AUTO_LIAB',
    value: 'HIRED',
  },
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'AUTO_LIAB',
    value: 'SCHEDULED',
  },
];

export const ENDORSEMENTS = [
  {
    type: 'ENDORSEMENT',
    name: 'ADDITIONAL_INSURED',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'WAIVER_OF_SUBROGATION',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'PRIMARY_NON_CONTRIBUTORY',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'LOSS_PAYEE',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'LENDERS_LOSS_PAYABLE',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'MORTGAGEE',
    value: '',
  },
  {
    type: 'ENDORSEMENT',
    name: 'OBLIGEE',
    value: '',
  },
];

export const GL = [
  {
    type: 'SUB_LIMIT',
    name: 'DMG_TO_RENTED_PREM',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'MED_EXP',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'PERS_ADV_INJ',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'PROD_COMP_OG_AGG',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'GL_THIRD_PRTY_LIAB',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  // Commented until product team announce these changes
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'APPLIES_PER_POLICY',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'APPLIES_PER_PROJECT',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'APPLIES_PER_LOCATION',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'POLICY_COVER_OCCURRENCE',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'POLICY_COVER_CLAIMS_MADE',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
];

export const COM_AUTO = [
  {
    type: 'SUB_LIMIT',
    name: 'BODY_INJ_PER_PER',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'BODY_INJ_PER_ACC',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'PROP_DAM_PER_ACC',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
];

export const WRK_COMP = [
  {
    type: 'SUB_LIMIT',
    name: 'PER_STATUTE',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'EL_EACH_ACC',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'EL_EACH_DIS_EA_EMP',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'EL_DIS_POL_LIM',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
];

export const PROPERTY = [
  {
    type: 'SUB_LIMIT',
    name: 'BACKUP_SEWER_DRAIN',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'SPOILAGE',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'ACTS_TERRORISM',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'EQUIPMENT_BREAKDOWN',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'WINDSTORM',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'FLOOD_ZONE_A',
    value: '0',
    meta: {
      typeValue: 'AMOUNT',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'BUILDING',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'BPP',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  // Commented until product team announce these changes
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'BUILDING_COVERAGE_REPLACEMENT_COST',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'BUSINESS_PERSONAL_PROPERTY_REPLACEMENT_COST',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'EARTHQUAKE_SPRINKLER_DAMAGE',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'NO_COINSURANCE',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'CAUSE_OF_LOSS_SPECIAL',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
];

export const TECH_EO = [
  {
    type: 'SUB_LIMIT',
    name: 'CYBER',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'EO',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'PROF_LIAB',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'ERRS_OMISNS',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
];

export const UMB_EXCESS = [
  {
    type: 'SUB_LIMIT',
    name: 'EXCESS_PRESENT',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
  {
    type: 'SUB_LIMIT',
    name: 'UMBRELLA_PRESENT',
    value: null,
    meta: {
      typeValue: 'BOOL',
    },
  },
];

export const EMPL_PRACT_LIAB = [];

export const EO_PROF_LIAB = [];

export const LIQUOR_LIAB = [
  // {
  //   type: 'SUB_LIMIT',
  //   name: 'ADDITIONAL_INSURED',
  //   value: null,
  //   meta: {
  //     typeValue: 'BOOL',
  //   },
  // },
];

export const SUB_LIMITS = {
  GL,
  COM_AUTO,
  WRK_COMP,
  PROPERTY,
  TECH_EO,
  EMPL_PRACT_LIAB,
  UMB_EXCESS,
};

export const ACTS_OF_TERROR = [
  {
    type: 'ADDITIONAL_COVERAGE',
    name: 'ACTS_OF_TERROR',
    value: null,
  },
];

const SUBREQUIREMENTS = {
  AUTO_LIAB,
  ENDORSEMENTS,
  SUB_LIMITS,
  ACTS_OF_TERROR,
};

export default SUBREQUIREMENTS;
