<template>
  <div>
    <v-checkbox
      v-for="coverage in coverages"
      :key="coverage.value"
      v-model="coverage.meta.selectedValue"
      color="aon-peacock"
      class="mt-0"
      :value="coverage.value"
      :label="$t(`policies.ADDITIONAL_COVERAGE.${getCoverageLabel(coverage)}`)"
      @change="!coverage.meta.selectedValue"
    />
  </div>
</template>

<script>
export default {
  name: 'AdditionalCoverages',
  props: {
    coverages: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  methods: {
    getCoverageLabel(coverage) {
      if (coverage.name === 'ACTS_OF_TERROR') {
        return `ACTS_OF_TERROR.ACTS_OF_TERROR`;
      } else {
        return `${coverage.name}${
          coverage.name === 'AUTO_LIAB' ? '.' : ''
        }${coverage.value ?? ''}`;
      }
    },
  },
};
</script>
