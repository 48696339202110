var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"policies"},[_c('h2',{staticClass:"aon-peacock--text pt-7 pb-6"},[_vm._v(_vm._s(_vm.$t('policies.pageTitle')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"policy-filters"},[_c('TableFiltersSelect',{attrs:{"filter-types":_vm.filterTypes,"bottom-chips":""},on:{"change":_vm.getPolicies}}),_c('v-text-field',{staticClass:"py-2",attrs:{"placeholder":_vm.$t('policies.search'),"outlined":"","dense":"","hide-details":""},on:{"input":_vm.filterPolicies},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}},[_c('v-icon',{attrs:{"slot":"append","small":""},slot:"append"},[_vm._v("fal fa-search")])],1)],1)]),_c('v-col',{staticClass:"text-right"},[_c('AppButton',{staticClass:"mr-2",attrs:{"variation":"subtle"},on:{"click":function($event){_vm.showUploadPolicyPdfDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('policies.addPdf'))+" ")]),_c('AppButton',{on:{"click":function($event){_vm.showCreatePolicyDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('policies.addPolicy'))+" ")])],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"hide-default-header":"","hide-default-footer":"","expanded":_vm.expanded,"headers":_vm.policiesHeaders,"items":_vm.policies,"loading":_vm.loading,"options":_vm.pagingOptions,"server-items-length":_vm.totalPolicies},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var ref_props = ref.props;
var headers = ref_props.headers;
var options = ref_props.options;
var sort = ref.on.sort;
return [_c('table-header',{attrs:{"headers":headers,"options":options},on:{"sort":sort}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('PolicyDetails',{key:("policy_details_" + (item.id)),attrs:{"policy-id":item.id},on:{"updated":_vm.policyUpdated}})],1)]}},{key:"item.policyNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"policy-number"},[_c('AppButton',{attrs:{"variation":"icon"},on:{"click":function($event){return _vm.expandRow(item)}}},[_c('i',{staticClass:"fal fa-angle-right"})]),_vm._v(" "+_vm._s(item.policyNumber || 'N/A')+" ")],1)]}},{key:"item.policyHolder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policyHolder || 'N/A')+" ")]}},{key:"item.insuranceType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("mappings.insurance." + (item.insuranceType))))+" ")]}},{key:"item.coverage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRequirement(item.insuranceType, item.coverage))+" ")]}},{key:"item.perOccurrenceCoverage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRequirement(item.insuranceType, item.perOccurrenceCoverage))+" ")]}},{key:"item.expirationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("longDate")(item.expirationDate))+" ")]}},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [_c('AppButton',{attrs:{"variation":"icon"},on:{"click":function($event){return _vm.expandRow(item)}}},[_c('i',{class:_vm.expanded.includes(item) ? 'far fa-minus' : 'far fa-plus'})])]}},{key:"footer",fn:function(ref){
var pagination = ref.props.pagination;
return [_c('table-footer',{attrs:{"pagination":pagination,"options":_vm.pagingOptions},on:{"update:pagination":function($event){pagination=$event},"update:options":function($event){_vm.pagingOptions=$event}}})]}}])})],1),_c('PolicyModal',{key:"PolicyModal",attrs:{"open":_vm.showCreatePolicyDialog,"policy":_vm.newPolicy(),"is-broker":""},on:{"update:open":function($event){_vm.showCreatePolicyDialog=$event},"updated":_vm.policyUpdated,"searchForDuplicate":_vm.searchForDuplicate}}),_c('PolicyUploadPdfModal',{key:"PolicyUploadPdfModal",attrs:{"open":_vm.showUploadPolicyPdfDialog,"policy":_vm.newPolicyPdf},on:{"update:open":function($event){_vm.showUploadPolicyPdfDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }