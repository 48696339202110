<template>
  <div>
    <v-row class="expanded-details elevation-1">
      <v-col>
        <Spinner v-if="loading" class="absolute-center" :width="50" />
        <template v-else>
          <v-row>
            <v-col class="d-flex align-center justify-start">
              <div>
                <span class="h5">
                  {{ $t(`mappings.insurance.${policy.insuranceType}`) }}
                </span>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex align-center justify-end">
                <v-menu v-model="showActionMenu" offset-y left>
                  <template #activator="{ on }">
                    <AppButton class="ml-4" variation="icon" v-on="on">
                      <i class="far fa-ellipsis-h" />
                    </AppButton>
                  </template>
                  <v-list class="py-0">
                    <v-list-item
                      :disabled="!documentId"
                      link
                      @click="downloadCoi()"
                    >
                      <v-list-item-title>
                        {{ $t('policies.details.viewCoi') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="policy && policy.coverageRequestId === null"
                      link
                      @click="goToCoverageRequest()"
                    >
                      <v-list-item-title>
                        {{ $t('policies.details.viewCoverageRequest') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="editPolicy = true">
                      <v-list-item-title>
                        {{ $t('policies.details.editPolicy') }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-menu
                      v-model="showConfirmDelete"
                      content-class="confirm-remove-policy"
                      offset-y
                      nudge-left="50%"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item link v-on="on">
                          <v-list-item-title class="aon-teal--text">
                            {{ $t('policies.details.removePolicy') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <div class="pa-4 white text-center">
                        <p class="mb-2">
                          {{ $t('policies.details.removeThisPolicy') }}
                        </p>
                        <p class="small">
                          {{ $t('policies.details.thisActionCanNotBeUndone') }}
                        </p>
                        <div class="d-flex align-center">
                          <AppButton
                            class="mr-4"
                            variation="subtle"
                            @click="showConfirmDelete = false"
                          >
                            {{ $t('policies.details.no') }}
                          </AppButton>
                          <AppButton
                            :loading="pendingDelete"
                            @click="deletePolicy"
                          >
                            {{ $t('policies.details.yes') }}
                          </AppButton>
                        </div>
                      </div>
                    </v-menu>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="additionalInsured">
            <v-col>
              <small>
                {{ $t('policies.additionalInsured') }}
              </small>
              <span>
                {{ additionalInsured }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-4 pb-0">
              <small>
                {{ $t('policies.details.deductible') }}
              </small>
              <span>
                {{ formatRequirement(policy.insuranceType, policy.deductible) }}
              </span>
            </v-col>
            <v-col class="pt-4 pb-0">
              <small>
                {{ $t('policies.details.territory') }}
              </small>
              <span>
                {{ territory }}
              </span>
            </v-col>
            <v-col class="pt-4 pb-0">
              <small>
                {{ $t('policies.details.policyType') }}
              </small>
              <span>
                {{ $t(`policies.details.policyTypeOptions.${policyType}`) }}
              </span>
            </v-col>
            <v-col class="pt-4 pb-0">
              <small>
                {{ $t('policies.details.premium') }}
              </small>
              <span>
                {{ premium }}
              </span>
            </v-col>
            <v-col class="pt-4 pb-0">
              <small>
                {{ $t('policies.details.bound') }}
              </small>
              <span>
                {{ bound }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <template v-if="editPolicy">
      <PolicyModal
        v-if="policy"
        :policy="policy"
        :open.sync="editPolicy"
        is-broker
        @updated="updated"
      />
    </template>
  </div>
</template>

<script>
import { deleteInsurancePolicy } from '@/api/insurancePolicies';
import { downloadDocument } from '@aon/cfs-insurance/src/api/document';
import { insuranceTypeRouteMap } from '@/lib/mappings';
import { mapState } from 'vuex';
import CoiUpload from '@/lib/forms/coi-upload';
import PolicyModal from '@/components/Policies/PolicyModal';
import { formatRequirement } from '@/lib/helpers';

export default {
  name: 'PolicyDetails',
  components: {
    PolicyModal,
  },
  props: {
    policyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      coi: null,
      insuranceTypeRouteMap,
      loadingPolicy: true,
      loadingDocument: false,
      loadingDocumentId: false,
      showActionMenu: false,
      coiUpload: CoiUpload(),
      editPolicy: false,
      showConfirmDelete: false,
      pendingDelete: false,
    };
  },
  computed: {
    ...mapState('insurancePolicy', {
      documentId: state => state.documentId,
      documentsData: state => state.documentsData,
      policies: state => state.policies,
      policy: state => state.policy,
    }),
    bound() {
      return this.policy
        ? this.$options.filters.longDate(this.policy.created)
        : '';
    },
    additionalInsured() {
      return this.policy?.additionalInsured;
    },
    territory() {
      return this.policy ? this.policy.territory : '';
    },
    policyType() {
      return this.policy ? this.policy.policyType : 'NA';
    },
    premium() {
      return this.policy
        ? this.$options.filters.currency(this.policy.premium)
        : '';
    },
    loading() {
      return this.loadingPolicy || this.loadingDocumentId;
    },
    isExpired() {
      return this.$moment.utc(this.policy.expirationDate).isBefore();
    },
    $v() {
      return this.coiUpload.$v;
    },
  },
  beforeMount() {
    this.$store.commit('insurancePolicy/setDocumentId', null);
    this.$store.commit('insurancePolicy/setPolicy', null);
  },
  mounted() {
    this.getPolicy();
    this.getDocumentData();
  },
  methods: {
    formatRequirement,
    async downloadCoi() {
      this.loadingDocument = true;

      try {
        const { data } = await downloadDocument(this.documentId);

        window.open(data, '_self');
      } catch (error) {
        this.$logger.error(error);
      }

      this.loadingDocument = false;
    },
    async getPolicy() {
      this.loadingPolicy = true;

      try {
        await this.$store.dispatch('insurancePolicy/getPolicy', this.policyId);
      } catch (error) {
        this.$logger.error(error);
      }
      this.loadingPolicy = false;
    },
    getDocumentData(forceRefresh = false) {
      this.loadingDocumentId = true;

      const cachedDocumentData = this.documentsData.find(
        p => p.correlationId === this.policyId
      );

      if (!forceRefresh && cachedDocumentData) {
        this.$store.commit(
          'insurancePolicy/setDocumentId',
          cachedDocumentData.id
        );
        this.loadingDocumentId = false;

        return;
      }

      try {
        this.$store.dispatch('insurancePolicy/getDocumentId', this.policyId);
      } catch (error) {
        this.docuemntId = null;
        this.$logger.error(error);
      }
      this.loadingDocumentId = false;
    },
    goToCoverageRequest() {
      this.$router.push({
        name: 'Coverage Request',
        params: {
          tenantId: this.policy.tenantId,
          type: insuranceTypeRouteMap[this.policy.insuranceType],
          id: this.policy.coverageRequestId,
        },
      });
    },
    async deletePolicy() {
      this.pendingDelete = true;
      try {
        await deleteInsurancePolicy(this.policy.id);
        await this.updated();
      } catch {
        this.$AppEventBus.$emit('snacktime', {
          message: this.$t('policies.details.removePolicyError'),
          type: 'error',
        });
      } finally {
        this.pendingDelete = false;
        this.showActionMenu = false;
      }
    },
    async updated() {
      this.$emit('updated');
      await this.getPolicy();
      await this.getDocumentData(true);
    },
  },
};
</script>

<style lang="scss">
.expanded-details {
  position: relative;
  margin: 1rem 0.5rem 1.5rem;
  padding: 1rem;
  color: $aon-gray-01;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $aon-gray-02;

  small {
    display: block;
    font-size: 0.8rem;
    color: $aon-gray-02;
  }
  span {
    display: inline-block;
    min-height: 1rem;
  }
}
</style>
