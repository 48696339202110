<template>
  <v-dialog v-model="open" max-width="640" persistent>
    <div id="insurance-modal" class="insurance-policy-modal">
      <Card>
        <div class="d-flex align-center justify-space-between mb-8">
          <h4>{{ title }}</h4>
          <AppButton variation="icon" @click="closeDialog">
            <i class="fal fa-times" />
          </AppButton>
        </div>

        <v-form class="add-policy-inner">
          <LabeledInput
            :label="$t('insurancePolicyModal.broker')"
            :required="true"
            :wrapped="false"
          >
            <v-radio-group v-model="$v.fields.brokeredBy.$model" dense row>
              <v-radio
                :label="$t('insurancePolicyModal.brokeredBy.aon')"
                value="aon"
              ></v-radio>
              <v-radio
                :label="$t('insurancePolicyModal.brokeredBy.coverwallet')"
                value="coverwallet"
              ></v-radio>
              <v-radio
                :label="$t('insurancePolicyModal.brokeredBy.other')"
                value="other"
              ></v-radio>
            </v-radio-group>
          </LabeledInput>

          <div v-if="$v.fields.brokeredBy.$model === 'other'">
            <LabeledInput
              :label="$t('insurancePolicyModal.brokerTextField')"
              :required="true"
            >
              <v-text-field
                v-model="$v.fields.brokerName.$model"
                :error-messages="policyForm.errors($v.fields.brokerName)"
                dense
                outlined
                :placeholder="$t('insurancePolicyModal.brokerTextField')"
              />
            </LabeledInput>
          </div>

          <AutocompleteCarriers
            :insurance="policyForm"
            :insurancelob="$v.fields"
          />

          <LabeledInput :label="$t('insurancePolicyModal.policyId')" required>
            <v-text-field
              v-model="$v.fields.policyNumber.$model"
              :error-messages="policyForm.errors($v.fields.policyNumber)"
              validate-on-blur
              outlined
              dense
              type="text"
            />
          </LabeledInput>

          <div v-if="!isAddMode">
            <p class="aon-peacock--text font-weight-bold">
              {{
                $t(
                  `insurancePolicyModal.typeSimple.${$v.fields.insuranceType.$model}`
                )
              }}
            </p>
          </div>

          <div v-else>
            <LabeledInput
              :label="$t('insurancePolicyModal.insuranceType')"
              :required="true"
            >
              <v-select
                v-model="$v.fields.insuranceType.$model"
                :error-messages="policyForm.errors($v.fields.insuranceType)"
                :items="
                  isMigrated ? lobOptions : policyForm.insuranceTypeOptions
                "
                :menu-props="'offset-y'"
                dense
                outlined
                @change="clearSelectedSubreqs"
              />
            </LabeledInput>
          </div>
          <LabeledInput
            :label="$t('policies.ENDORSEMENT.clientName')"
            :required="true"
            dense
          />
          <v-text-field
            v-if="!companies.length"
            v-model="$v.fields.clientName.$model"
            :error-messages="policyForm.errors($v.fields.clientName)"
            outlined
            dense
            clearable
            hide-details
            @click:clear="$v.fields.namedEndorsements.$model = []"
          />
          <v-select
            v-else
            v-model="$v.fields.clientName.$model"
            :error-messages="policyForm.errors($v.fields.clientName)"
            :items="companies"
            :menu-props="'offset-y'"
            dense
            clearable
            outlined
            @click:clear="$v.fields.namedEndorsements.$model = []"
          />
          <div v-if="endorsementsAvailable && !isMigrated" class="mb-4">
            <div v-if="$v.fields.clientName.$model" class="mb-2">
              <LabeledInput
                :label="$t('policies.COI_CLIENT.title')"
                class="mb-2 mt-4"
              />
              <v-checkbox
                v-model="$v.fields.coiClientSubRequirements.$model"
                color="aon-peacock"
                class="ml-3 mt-0"
                value="CERTIFICATE_HOLDER"
                :label="
                  $t(`policies.COI_CLIENT.CERTIFICATE_HOLDER.description`)
                "
              />
              <LabeledInput
                :label="$t('policies.ENDORSEMENT.title')"
                class="my-2"
              ></LabeledInput>

              <v-checkbox
                v-for="end in subRequirements.ENDORSEMENTS"
                :key="end.name"
                v-model="$v.fields.namedEndorsements.$model"
                color="aon-peacock"
                class="ml-3 mt-0"
                :value="end.name"
                :label="$t(`policies.${end.type}.${end.name}`)"
              />
            </div>
          </div>

          <div
            v-if="getAdditionalCoverageForPolicyType.length > 0 && !isMigrated"
          >
            <v-checkbox
              v-model="showAdditionalCoverages"
              color="aon-peacock"
              :value="showAdditionalCoverages"
              class="mt-0 mb-0 label"
              :label="
                $t(
                  `policies.ADDITIONAL_COVERAGE.title.${$v.fields.insuranceType.$model}`
                )
              "
              @change="!showAdditionalCoverages"
            />
          </div>

          <div
            v-if="showAdditionalCoverages && !isMigrated"
            class="ml-3 mt-0 mb-0"
          >
            <AdditionalCoverages
              :coverages="policyForm.fields.additionalCoverages"
            />
          </div>

          <v-checkbox
            v-if="getSublimitsByPolicyType.length > 0 && !isMigrated"
            v-model="showSublimits"
            color="aon-peacock"
            class="mt-0 mb-0 label"
            :label="$t('policies.SUB_LIMIT.title')"
          />

          <div
            v-if="
              getSublimitsByPolicyType.length > 0 &&
                showSublimits &&
                !isMigrated
            "
            class="ml-3 mt-0 mb-0"
          >
            <SublimitsBooleanItems
              :insurance-type="fields.insuranceType"
              :sublimits="policyForm.fields.booleanSublimits"
            />

            <SublimitsAmountItems
              :insurance-type="fields.insuranceType"
              :sublimits="policyForm.fields.amountSublimits"
            />
          </div>

          <LabeledInput
            v-if="showLimitsAndDeductibleField('perOccurrence') && !isMigrated"
            :label="showLabelPerOccurrence($v.fields.insuranceType.$model)"
            :required="true"
            class="text-no-wrap"
          >
            <v-text-field
              v-model.number="$v.fields.perOccurrenceCoverage.$model"
              :error-messages="
                policyForm.errors($v.fields.perOccurrenceCoverage)
              "
              type="number"
              class="hide-number-spinner"
              dense
              outlined
              :suffix="requirementSuffix"
              :prepend-inner-icon="requirementIcon"
              @blur="$v.fields.coverage.$touch"
            />
          </LabeledInput>

          <LabeledInput
            v-if="showLimitsAndDeductibleField('coverage') && !isMigrated"
            class="text-no-wrap"
            :label="$t('insurancePolicyModal.coverageAmount')"
            :required="this.$v.fields.insuranceType.$model !== 'WRK_COMP'"
          >
            <v-text-field
              v-model.number="$v.fields.coverage.$model"
              :error-messages="policyForm.errors($v.fields.coverage)"
              type="number"
              class="hide-number-spinner"
              dense
              outlined
              :suffix="requirementSuffix"
              :prepend-inner-icon="requirementIcon"
              @blur="$v.fields.perOccurrenceCoverage.$touch"
            />
          </LabeledInput>

          <LabeledInput
            v-if="showLimitsAndDeductibleField('deductible') && !isMigrated"
            :label="$t('insurancePolicyModal.deductible')"
            :required="this.$v.fields.insuranceType.$model !== 'WRK_COMP'"
          >
            <v-text-field
              v-model.number="$v.fields.deductible.$model"
              :error-messages="policyForm.errors($v.fields.deductible)"
              type="number"
              class="hide-number-spinner"
              dense
              outlined
              :suffix="requirementSuffix"
              :prepend-inner-icon="requirementIcon"
            />
          </LabeledInput>

          <div v-if="lob">
            <BooleanCoverages
              :items="booleanCoverages"
              :is-edit-view="!isAddMode"
              :policy-coverages="existingPolicyCoverages"
              @change="updateBooleanCoverages"
            />
          </div>

          <LabeledInput
            :label="$t('insurancePolicyModal.territory')"
            :required="true"
            class="mt-1"
          >
            <v-autocomplete
              v-model="$v.fields.territory.$model"
              :error-messages="policyForm.errors($v.fields.territory)"
              :items="policyForm.territoryTypeOptions"
              :menu-props="'offset-y'"
              dense
              outlined
            />
          </LabeledInput>

          <LabeledInput
            :label="$t('insurancePolicyModal.coverageStartDate')"
            :required="true"
          >
            <DatePicker
              :date.sync="effectiveDate"
              :error-messages="policyForm.errors($v.fields.effectiveDate)"
              label=""
              dense
            />
          </LabeledInput>

          <LabeledInput
            :label="$t('insurancePolicyModal.expirationDate')"
            :required="true"
          >
            <DatePicker
              :date.sync="expirationDate"
              :error-messages="policyForm.errors($v.fields.expirationDate)"
              label=""
              dense
            />
          </LabeledInput>

          <LabeledInput
            :label="$t('insurancePolicyModal.certificateOfInsurance')"
            :required="true"
            :wrapped="false"
          >
            <FileInput
              v-model="$v.fields.certificateOfInsurance.$model"
              :error-messages="
                policyForm.errors($v.fields.certificateOfInsurance)
              "
            >
              <template v-if="file" #previousFile>
                <i18n
                  path="form.previouslyUploaded"
                  class="p small font-italic"
                >
                  <button class="aon-teal--text">
                    {{ file.fileName }}
                  </button>
                </i18n>
              </template>
            </FileInput>
          </LabeledInput>

          <LabeledInput
            :label="$t('insurancePolicyModal.policyType')"
            :required="true"
            :wrapped="false"
          >
            <v-radio-group
              v-model="$v.fields.policyType.$model"
              class="mt-0"
              :error-messages="policyForm.errors($v.fields.policyType)"
              row
            >
              <v-radio
                color="aon-teal"
                value="Broad"
                :label="$t('insurancePolicyModal.broad')"
              />
              <v-radio
                color="aon-teal"
                value="Exclusive"
                :label="$t('insurancePolicyModal.clientExclusive')"
              />
            </v-radio-group>
          </LabeledInput>
          <!-- <LabeledInput
            v-if="$v.fields.brokeredBy.$model === 'coverwallet'"
            :label="$t('policies.coverWalletAccountId')"
          >
            <v-text-field
              v-model="$v.fields.coverWalletAccountId.$model"
              :error-messages="
                policyForm.errors($v.fields.coverWalletAccountId)
              "
              validate-on-blur
              outlined
              dense
              type="text"
            />
          </LabeledInput> -->
          <LabeledInput
            :label="$t('insurancePolicyModal.vendorSearchTitle')"
            :required="policyForm.isRequired($v.fields.vendorId)"
          >
            <v-autocomplete
              v-model="vendorId"
              :error-messages="policyForm.errors($v.fields.vendorId)"
              :items="vendors"
              :loading="vendorsSearching"
              :menu-props="autocompleteMenuProps"
              :readonly="!isAddMode"
              :disabled="!isAddMode"
              :search-input.sync="vendorsSearch"
              dense
              item-value="id"
              item-text="name"
              outlined
              clearable
              :placeholder="$t('insurancePolicyModal.vendorSearch')"
              @click="checkVendor"
              @click:clear="clearSearch"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    <Spinner
                      v-if="vendorsSearching"
                      class="absolute-center"
                      :width="25"
                    ></Spinner>
                    <template v-else>
                      {{
                        $t(
                          `insurancePolicyModal.${
                            vendorsHasSearched ? 'noVendors' : 'vendorSearch'
                          }`
                        )
                      }}
                    </template>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="!showVendorForm" v-slot:append-item>
                <AppButton
                  class="mt-2 mx-auto"
                  full-width
                  @click="addVendorSelected"
                >
                  {{ $t('policies.addVendor') }}
                </AppButton>
              </template>
            </v-autocomplete>
          </LabeledInput>
          <div v-if="showVendorForm && !$v.fields.vendorId.$model">
            <v-divider class="my-2" />
            <VendorFields ref="vendorFields" />
          </div>

          <Alert v-if="error" persistent full-width type="error" class="mt-2">
            {{ error }}
          </Alert>
          <div class="d-flex mt-2">
            <AppButton
              v-if="!duplicatePolicyId"
              class="mr-2"
              :loading="saving"
              @click="savePolicy"
            >
              {{ $t('form.saveChanges') }}
            </AppButton>
            <AppButton v-else @click="closeDialogForDuplicate">
              {{ $t('policies.duplicateReview') }}
            </AppButton>
            <AppButton
              :disabled="saving"
              variation="ghost"
              @click="closeDialog"
            >
              {{ $t('form.cancel') }}
            </AppButton>
          </div>
        </v-form>
      </Card>
    </div>
  </v-dialog>
</template>

<script>
import AutocompleteCarriers from '@/components/CoiReview/AutocompleteCarriers';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import FileInput from '@aon/cfs-components/src/components/Forms/FileInput';
import InsurancePolicyForm from '@/lib/forms/insurance-policy';
import AdditionalCoverages from '@/components/AdditionalCoverages';
import SublimitsAmountItems from '@/components/SublimitsAmountItems';
import SublimitsBooleanItems from '@/components/SublimitsBooleanItems';

import {
  createApprovedInsurancePolicy,
  updateInsurancePolicy,
  uploadInsuranceCertificate,
} from '@/api/policies';
import { debounce } from 'lodash-es';
import { searchVendors } from '@aon/cfs-insurance/src/api/vendors';
import { getVendor, getLob, getLobList } from '@/api/insurancePolicies';
import VendorFields from '@/components/Policies/VendorFields';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';
import {
  createFullVendor,
  getVendorClients,
  updateVendor,
} from '@/api/vendors';
import { v4 as uuidv4 } from 'uuid';

import { SUBREQUIREMENTS, LIMITS_MATRIX } from '@/lib/constants';
import BooleanCoverages from '@/components/CoverageMatrix/BooleanCoverages.vue';

export default {
  name: 'PolicyModal',
  components: {
    AutocompleteCarriers,
    VendorFields,
    DatePicker,
    LabeledInput,
    FileInput,
    AdditionalCoverages,
    SublimitsAmountItems,
    SublimitsBooleanItems,
    BooleanCoverages,
  },
  mixins: [scrollToFirstError],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
    file: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      customSubrequirementsFromPolicy: [],
      policyForm: InsurancePolicyForm(this.policy),
      saving: false,
      error: null,
      vendors: [],
      companies: [],
      vendorsHasSearched: false,
      vendorsSearch: null,
      vendorsSearching: false,
      showVendorForm: false,
      autocompleteMenuProps: {
        closeOnContentClick: true,
        maxHeight: 140,
      },
      vendorId: null,
      showSublimits: false,
      duplicatePolicyId: null,
      showAdditionalCoverages: false,
      formSubmitted: false,
      isMigrated: false,
      selectedInsuranceType: '',
      lob: null,
      coverages: {
        booleanCoverages: {},
      },
      lobOptions: [],
      existingPolicyCoverages: [],
    };
  },
  computed: {
    insuranceType() {
      return this.$v.fields.insuranceType.$model;
    },
    booleanCoverages() {
      return (
        this.lob.coverages.filter(coverage => coverage.fieldType === 1) || []
      );
    },
    showInitialSublimits() {
      return (
        SUBREQUIREMENTS['SUB_LIMITS'][`${this.policy?.insuranceType}`] ?? []
      );
    },
    isAddMode() {
      return this.policyForm.fields.id === null;
    },
    endorsementsAvailable() {
      return !!this.$v.fields.vendorId.$model || this.showVendorForm;
    },
    title() {
      return this.isAddMode
        ? this.$t('insurancePolicyModal.addPolicy')
        : this.$t('insurancePolicyModal.policyDetails');
    },
    fields() {
      return this.policyForm.fields;
    },
    $v() {
      return this.policyForm.$v;
    },
    effectiveDate: {
      get() {
        return this.$v.fields.effectiveDate.$model
          ? this.$moment
              .utc(this.$v.fields.effectiveDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.effectiveDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
    expirationDate: {
      get() {
        return this.$v.fields.expirationDate.$model
          ? this.$moment
              .utc(this.$v.fields.expirationDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.expirationDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
    subRequirements() {
      return SUBREQUIREMENTS;
    },
    requirementSuffix() {
      const policy = this.policyForm.getFormValues();
      switch (policy.insuranceType) {
        case 'BUSINESS':
          return this.$t('policies.details.unitOfMeasure.days');
        default:
          return null;
      }
    },
    requirementIcon() {
      const policy = this.policyForm.getFormValues();
      switch (policy.insuranceType) {
        case 'BUSINESS':
          return null;
        default:
          return 'fal fa-dollar-sign';
      }
    },
    getSublimitsByPolicyType() {
      const policy = this.policyForm.getFormValues();
      const sublimits =
        SUBREQUIREMENTS['SUB_LIMITS'][`${policy.insuranceType}`] ?? [];
      return sublimits;
    },
    getAdditionalCoverageForPolicyType() {
      const policy = this.policyForm.getFormValues();
      let subrequirements = null;
      switch (policy.insuranceType) {
        case 'GL':
          subrequirements = SUBREQUIREMENTS['AUTO_LIAB'].filter(
            ac => ac.value == 'HIRED' || ac.value == 'NON_OWNED'
          );
          for (const sr of subrequirements) {
            const existingSR = this.fields.additionalCoverages.find(
              ac =>
                ac.name == sr.name && ac.type == sr.type && ac.value == sr.value
            );
            if (existingSR) {
              sr.id = existingSR.id;
            }
          }
          return subrequirements;
        case 'COM_AUTO':
          subrequirements = SUBREQUIREMENTS['AUTO_LIAB'];
          for (const sr of subrequirements) {
            const existingSR = this.fields.additionalCoverages.find(
              ac =>
                ac.name == sr.name && ac.type == sr.type && ac.value == sr.value
            );
            if (existingSR) {
              sr.id = existingSR.id;
            }
          }
          return subrequirements;
        default:
          return [];
      }
    },
  },
  watch: {
    getAdditionalCoverageForPolicyType() {
      const { insuranceType } = this.policyForm.getFormValues();
      if (this.selectedInsuranceType !== insuranceType) {
        this.selectedInsuranceType = insuranceType;
        this.getConfig();
      }
    },
    isMigrated() {
      this.getConfig();
    },
    policy() {
      this.policyForm = InsurancePolicyForm(this.policy);
    },
    vendorsSearch: debounce(async function(query) {
      this.hasSearched = true;

      if (this.vendorsSearching || query === null || query.length < 3) {
        return;
      }
      this.vendorsSearching = true;

      await searchVendors(query)
        .then(response => (this.vendors = response.data.results))
        .catch(() => (this.searchError = true))
        .finally(() => (this.vendorsSearching = false));
    }, 200),
    async vendorId(value) {
      this.$v.fields.vendorId.$model = value;
      await getVendor(value).then(response => {
        this.isMigrated = response.data.migrated;
      });
      this.$v.fields.coverWalletAccountId.$model = this.vendors.find(
        v => v.id === value
      )?.coverWalletAccountId;
      const { data } = await getVendorClients(value);
      this.companies = data.results.map(cl => cl.companyName);
    },
  },
  async created() {
    if (!this.isAddMode) {
      const { data } = await getVendorClients(this.policy.vendorId);
      this.companies = data.results.map(cl => cl.companyName);
      await getVendor(this.policy.vendorId)
        .then(response => {
          this.vendors = [response.data];
          this.vendorId = this.vendors[0]?.id;
          this.$v.fields.coverWalletAccountId.$model = this.vendors[0]?.coverWalletAccountId;
        })
        .catch(() => (this.searchError = true));
    }
  },
  async mounted() {
    this.selectedInsuranceType = this.insuranceType;

    const { data } = await getLobList();
    this.lobOptions = data.map(insurance => {
      return {
        text: insurance.name,
        value: insurance.lobTag,
      };
    });

    this.customSubrequirementsFromPolicy =
      this.policyForm.fields?.subrequirements || [];

    if (!this.isAddMode) {
      this.existingPolicyCoverages =
        this.policyForm.fields.subrequirements || [];
      const additionalCoverages = this.policyForm.fields?.additionalCoverages.filter(
        coverage => coverage.meta.selectedValue
      );

      if (additionalCoverages.length) {
        this.showAdditionalCoverages = true;
      }

      const booleanTypes = this.policyForm.fields?.booleanSublimits.filter(
        sublimit => sublimit.meta.selectedValue
      );
      const amountTypes = this.policyForm.fields?.amountSublimits.filter(
        sublimit => sublimit.value !== '0'
      );

      if (booleanTypes.length || amountTypes.length) {
        this.showSublimits = true;
      }
    }
  },
  destroyed() {
    if (this.policy.brokeredBy === 'other') {
      this.$store.commit('insurancePolicy/setPolicy', {
        ...this.policy,
        brokeredBy: this.policy.brokerName,
      });
    }
  },
  methods: {
    updateBooleanCoverages(coverage) {
      this.coverages.booleanCoverages[coverage.id] = {
        value: coverage.checked,
        meta: {},
        coverageId: coverage.id,
      };
    },
    async getConfig() {
      if (!this.isMigrated || !this.selectedInsuranceType) {
        this.lob = null;
        return;
      }

      const { data } = await getLob(this.selectedInsuranceType);
      this.lob = data[0];
    },
    checkVendor() {
      if (this.$v.fields.vendorId.$model) {
        this.showVendorForm = false;
      }
    },
    clearSearch() {
      this.$v.fields.vendorId.$model = null;
      this.vendors = [];
      this.showVendorForm = false;
      this.vendorsHasSearched = false;
    },
    addVendorSelected() {
      this.$v.fields.vendorId.$model = false;
      this.vendors = [];
      this.showVendorForm = true;
      this.vendorsHasSearched = false;
    },
    buildCoverages() {
      const subrequirements = [];
      const { booleanCoverages } = this.coverages;
      for (const coverage of Object.keys(booleanCoverages)) {
        const tempCoverage = booleanCoverages[coverage];
        if (tempCoverage.value) {
          subrequirements.push({ ...tempCoverage, value: 'true' });
        }
      }

      return subrequirements;
    },
    async savePolicy() {
      this.$v.fields.perOccurrenceCoverage.$model =
        this.$v.fields.perOccurrenceCoverage.$model ?? 0;
      this.$v.fields.deductible.$model = this.$v.fields.deductible.$model ?? 0;
      this.$v.$touch();
      const vendorFields = this.$refs.vendorFields;
      if (this.showVendorForm && vendorFields) {
        const vendorForm = vendorFields.$v;
        vendorForm.$touch();
        if (vendorForm.$invalid) {
          return;
        }
      }
      if (this.$v.$invalid) {
        return;
      }

      this.saving = true;

      const policy = this.policyForm.getFormValues();
      const brokerName =
        policy.brokeredBy === 'other' ? policy.brokerName : policy.brokeredBy;
      policy.brokeredBy = brokerName;
      policy.coverage = policy.coverage ?? 0;
      policy.perOccurrenceCoverage = policy.perOccurrenceCoverage ?? 0;
      policy.deductible = policy.deductible ?? 0;
      policy.subrequirements = this.lob
        ? this.buildCoverages()
        : this.buildSubRequirements(policy);
      try {
        let tenantId;
        let cwAccountIdUpdated = false;
        const vendor = !this.showVendorForm
          ? this.vendors.find(v => v.id === policy.vendorId)
          : {};
        if (!this.showVendorForm) {
          tenantId = vendor.companyId;
          cwAccountIdUpdated =
            policy.coverWalletAccountId !== vendor.coverWalletAccountId;
          vendor.coverWalletAccountId = policy.coverWalletAccountId;
        }

        if (this.isAddMode) {
          if (this.showVendorForm && vendorFields) {
            const result = await createFullVendor({
              name: vendorFields.vendorForm.getName(),
              address: vendorFields.vendorForm.getAddress(),
              proxyCompanyId: vendorFields.$v.fields.proxyCompanyId.$model,
              coverWalletAccountId: policy.coverWalletAccountId,
            });
            tenantId = result.data.companyId;
            policy.vendorId = result.data.vendorId;
          }
          policy.id = uuidv4();
          const { data } = await createApprovedInsurancePolicy(
            policy,
            tenantId
          );
          policy.id = data;
        } else {
          policy.id = this.policy.id;
          await updateInsurancePolicy(policy.id, policy, tenantId);
        }

        if (!this.showVendorForm && cwAccountIdUpdated) {
          await updateVendor(vendor);
        }
        if (policy.certificateOfInsurance) {
          await uploadInsuranceCertificate({
            file: policy.certificateOfInsurance,
            policyId: policy.id,
            tenantId: tenantId,
          });
        }

        this.$emit('updated');
        this.closeDialog();
        this.formSubmitted = true;
      } catch (error) {
        if (error.response?.status == '400' && error.response.data.policyId) {
          this.duplicatePolicyId = error.response.data.policyId;
          this.error = this.$t('policies.duplicateError');
        } else {
          this.error = this.$t('global.genericErrorMsg');
        }
      }

      this.saving = false;
    },
    closeDialog() {
      if (this.formSubmitted) {
        this.vendorId = null;
        this.clearSearch();
      }
      this.$emit('update:open', false);
    },
    buildSubRequirements(policy) {
      let subrequirements = [];

      if (policy.clientName) {
        policy.coiClientSubRequirements.forEach(ccr => {
          subrequirements.push({
            type: 'COI_CLIENT',
            name: ccr,
            value: policy.clientName,
          });
        });
        subrequirements.push({
          type: 'COI_CLIENT',
          name: 'CLIENT_COMPANY_NAME',
          value: policy.clientName,
        });
      }

      policy.namedEndorsements.forEach(endorsement => {
        subrequirements.push({
          type: 'ENDORSEMENT',
          name: endorsement,
          value: policy.clientName,
        });
      });

      if (policy.additionalCoverages.length) {
        policy.additionalCoverages.forEach(additionalCoverage => {
          if (additionalCoverage.meta.selectedValue) {
            delete additionalCoverage.meta;
            subrequirements.push(additionalCoverage);
          }
        });
      }

      if (policy.amountSublimits.length) {
        policy.amountSublimits.forEach(sublimit => {
          subrequirements.push(sublimit);
        });
      }

      if (policy.booleanSublimits.length) {
        policy.booleanSublimits.forEach(sublimit => {
          if (sublimit.meta.selectedValue) {
            delete sublimit.meta.selectedValue;
            subrequirements.push(sublimit);
          }
        });
      }

      const currentAdditionalCoverages = subrequirements.filter(
        sub => sub.type === 'ADDITIONAL_COVERAGE'
      );

      if (currentAdditionalCoverages.length) {
        this.showAdditionalCoverages = true;
      } else {
        this.showAdditionalCoverages = false;
      }

      return subrequirements;
    },
    clearSelectedSubreqs() {
      this.showSublimits = false;
      this.policyForm.fields.sublimits = [];
      this.customSubrequirementsFromPolicy = [];
      this.policyForm.fields.selectedAdditionalCoverages = [];
      this.policyForm.fields.additionalCoverages = [];

      if (this.isAddMode) {
        this.fillAdditionalCoverages();
        this.fillSublimitsItems();
      }
    },
    fillSublimitsItems() {
      let amounts = [];
      let booleans = [];
      this.getSublimitsByPolicyType.forEach(sublimit => {
        if (sublimit.meta.typeValue === 'AMOUNT') {
          amounts.push({ ...sublimit });
        }

        if (sublimit.meta.typeValue === 'BOOL') {
          booleans.push({
            ...sublimit,
            meta: { ...sublimit.meta, selectedValue: null },
          });
        }
      });

      this.policyForm.fields.amountSublimits = amounts;
      this.policyForm.fields.booleanSublimits = booleans;
    },
    fillAdditionalCoverages() {
      let subrequirements = [];
      const insuranceType = this.policyForm.fields.insuranceType;

      if (insuranceType === 'GL') {
        subrequirements = SUBREQUIREMENTS['AUTO_LIAB'].filter(
          coverage =>
            coverage.value === 'HIRED' || coverage.value === 'NON_OWNED'
        );
      }

      if (insuranceType === 'COM_AUTO') {
        subrequirements = SUBREQUIREMENTS['AUTO_LIAB'];
      }

      this.policyForm.fields.additionalCoverages = subrequirements.map(sub => {
        return {
          ...sub,
          meta: {
            selectedValue: null,
          },
        };
      });
    },
    showLabelPerOccurrence(type) {
      switch (type) {
        case 'LIQUOR_LIAB':
          return this.$t('insurancePolicyModal.eachCommonCauseAmount');
        case 'EO_PROF_LIAB':
          return this.$t('insurancePolicyModal.eachClaim');
        case 'BUSINESS':
          return this.$t('insurancePolicyModal.periodOfIndemnity');
        case 'COM_AUTO':
          return this.$t('insurancePolicyModal.combinedSingleLimit');
        case 'UMB_EXCESS':
          return this.$t('insurancePolicyModal.eachOccurrence');
        default:
          return this.$t('insurancePolicyModal.perOccurrenceCoverageAmount');
      }
    },
    showLimitsAndDeductibleField(field) {
      const policy = this.policyForm.getFormValues();
      const insuranceType = policy.insuranceType;
      switch (field) {
        case 'coverage':
          if (insuranceType === 'PROPERTY') return false;
          if (insuranceType === 'WRK_COMP' && !this.$v.fields.coverage.$model) {
            this.$v.fields.coverage.$model = 0;
            return false;
          }
          return LIMITS_MATRIX.COVERAGE.indexOf(insuranceType) > -1;
        case 'perOccurrence':
          if (
            (insuranceType === 'WRK_COMP' &&
              !this.$v.fields.perOccurrenceCoverage.$model) ||
            insuranceType === 'EMPL_PRACT_LIAB'
          ) {
            this.$v.fields.perOccurrenceCoverage.$model = 0;
            return false;
          }
          return LIMITS_MATRIX.PER_OCCURRENCE.indexOf(insuranceType) > -1;
        case 'deductible':
          if (
            (insuranceType === 'WRK_COMP' &&
              !this.$v.fields.deductible.$model) ||
            insuranceType === 'LIQUOR_LIAB' ||
            insuranceType === 'COM_AUTO' ||
            insuranceType === 'EMPL_PRACT_LIAB' ||
            insuranceType === 'UMB_EXCESS'
          ) {
            this.$v.fields.deductible.$model = 0;
            return false;
          }
          return LIMITS_MATRIX.DEDUCTIBLE.indexOf(insuranceType) > -1;
        default:
          return false;
      }
    },
    closeDialogForDuplicate() {
      this.$emit('searchForDuplicate', this.duplicatePolicyId);
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-policy-modal {
  .add-policy-inner {
    width: 460px;
  }

  .col {
    max-height: 5rem !important;
  }
}
</style>
